import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { Textarea } from "../../../../../common/inputs";
import { validateRemoveRequest } from "./AdminRequestValidator";

export const RemoveRequestForm = (props) => (
  <form
    onSubmit={props.handleSubmit}
    data-testid='remove-request-form'
    aria-label='Remove Request Form'
  >
    <div>
      Are you sure you want to remove this request? If so, please provide a
      detailed justification for return.
    </div>
    <div className='form-group p-15'>
      <Field
        component={Textarea}
        id='removalReason'
        name='removalReason'
        placeholder='Please enter reason for request removal.'
      />
    </div>
  </form>
);

RemoveRequestForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: "removeRequestForm",
  warn: validateRemoveRequest,
})(RemoveRequestForm);
