import React from "react";

// Define banner types
const BANNER_TYPES = {
  NONE: "NONE",
  SHUTDOWN: "SHUTDOWN",
  MAINTENANCE: "MAINTENANCE",
  ANNOUNCEMENT: "ANNOUNCEMENT",
};

// Banner content configurations
const bannerContents = {
  [BANNER_TYPES.SHUTDOWN]: {
    type: "danger", // red
    title: "Announcement:",
    icon: "exclamation-circle",
    content: (
      <>
        <p>
          Because of a lapse in government funding, the information on this
          website may not be up to date, transactions submitted via the website
          may not be processed, and the agency may not be able to respond to
          inquiries until appropriations are enacted. The NIH Clinical Center
          (the research hospital of NIH) is open. For more details about its
          operating status, please visit{" "}
          <a
            href={"https://cc.nih.gov"}
            target={"_blank"}
            rel='noopener noreferrer'
          >
            cc.nih.gov
          </a>
          . Updates regarding government operating status and resumption of
          normal operations can be found at{" "}
          <a
            href={"https://opm.gov"}
            target={"_blank"}
            rel='noopener noreferrer'
          >
            opm.gov
          </a>
          .
        </p>
        <hr />
        <p>
          Ante la falta de fondos del gobierno federal, no se actualizará este
          sitio web y la organización no responderá a transacciones ni consultas
          hasta que se aprueben los fondos. El Centro Clínico de los Institutos
          Nacionales de la Salud (el hospital de investigación) permanecerá
          abierto. Consulte{" "}
          <a href={"https://cc.nih.gov"} target={"_blank"}>
            cc.nih.gov
          </a>{" "}
          (en inglés). Infórmese sobre el funcionamiento del gobierno federal y
          el reinicio de las actividades en{" "}
          <a href={"https://opm.gov"} target={"_blank"}>
            opm.gov
          </a>
          .
        </p>
      </>
    ),
  },
  [BANNER_TYPES.MAINTENANCE]: {
    type: "warning", // yellow
    title: "Maintenance Alert:",
    icon: "exclamation-triangle-fill",
    content: (
      <p>
        DASH will be unavailable on{" "}
        <span className='mr-1' style={{ fontWeight: "bold" }}>
          Sunday, November 24, 2024
        </span>{" "}
        to undergo scheduled maintenance. During this time, you may experience
        limited or no access to DASH. We appreciate your patience and
        understanding as we perform important updates to improve our platform.
      </p>
    ),
  },
  [BANNER_TYPES.ANNOUNCEMENT]: {
    type: "info", // blue
    title: "Attention:",
    icon: "info-circle",
    content: (
      <>
        <span style={{ paddingBottom: "1rem", display: "inline-block" }}>
          This repository is under review for potential modification in
          compliance with Administration directives.
        </span>
      </>
    ),
  },
};

export { BANNER_TYPES, bannerContents };
