import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router-for-react18";
import ReactGA from "react-ga4";
import store, { history } from "./app/store/store";
import "./index.scss";
import App from "./app/App";

const MOUNT_NODE = document.getElementById("root");
const userId = localStorage.getItem("id");
const accountNumber =
  window.location.origin === "https://dash.nichd.nih.gov"
    ? "G-3E3XKSS7XP"
    : "G-WNPZ529F6E";

ReactGA.initialize(accountNumber, {
  gaOptions: {
    cookieFlags: "SameSite=Strict;Secure",
    userId,
  },
});

const root = createRoot(MOUNT_NODE);
root.render(
  // Disabled strict mode to accommodate outdated components during
  //    development. It is not used in a production build anyway.

  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  // </React.StrictMode>,
);
