import styled, { css, keyframes } from "styled-components";

// If position is 'fixed' the spinner covers entire page, otherwise if position is 'absolute' the spinner only covers the containing component
export const Overlay = styled.div`
  position: ${(props) => props.position};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  transition: opacity ${(props) => props.speed}ms ease-out;
  display: flex;
  text-align: center;
  font-size: 1.2em;
  z-index: ${(props) => props.zIndex};

  &._loading-overlay-transition-enter {
    opacity: 0.01;
  }

  &._loading-overlay-transition-enter-active {
    opacity: 1;
    transition: opacity ${(props) => props.speed}ms ease-in;
  }

  &._loading-overlay-transition-exit {
    opacity: 1;
  }

  &._loading-overlay-transition-exit-active {
    opacity: 0;
    transition: opacity ${(props) => props.speed}ms ease-in;
  }
`;

export const Spinner = styled.div`
  position: relative;
  margin: 0 auto 10px auto;
  width: ${(props) => props.spinnerSize};
  max-height: 100%;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

export const Content = styled.div`
  margin: auto;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotate360Animation = css`
  ${rotate360} 2s linear infinite
`;

// set position to 'relative' if spinner should cover entire page, otherwise set position to 'absolute'
export const Svg = styled.svg`
  animation: ${rotate360Animation};
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: ${(props) => props.position};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const spinnerDash = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
`;

const spinnerDashAnimation = css`
  ${spinnerDash} 1.5s ease-in-out infinite
`;

// changed stroke-dasharray from '1,200' to '12' to work on IE
export const Circle = styled.circle`
  animation: ${spinnerDashAnimation};
  stroke-dasharray: 12;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: ${(props) => props.color};
`;
