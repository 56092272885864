import React from "react";
import PropTypes from "prop-types";

const AddressPrettyPrint = ({ address = {} }) => {
  const { address1, address2, city, state, country, zip } = address;

  // Foregin addresses may not have a state, show country instead
  const region = state || country;
  const zipCode = zip || "";

  return (
    <div>
      <p>
        {address1} <br />
        {address2 && (
          <span>
            {address2} <br />
          </span>
        )}
        {`${city}, ${region} ${zipCode}`}
      </p>
    </div>
  );
};

AddressPrettyPrint.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }),
};

export default AddressPrettyPrint;
