import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router-for-react18";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import rootReducer from "./reducers";

export const history = createBrowserHistory();
const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];
if (process.env.REACT_APP_DEVTOOLS === "true") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(
      devToolsExtension({
        trace: true,
      }),
    );
  }
}
const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
const store = createStore(
  rootReducer(history),
  initialState,
  composedEnhancers,
);

export default store;
