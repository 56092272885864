import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import "../Button/style.scss";
import { Button } from "..";

const YellowButton = (props) => {
  const combinedClasses = `btn btn-yellow ${props.className}`;
  const styles = {
    float: props.align,
  };
  return (
    <Button
      data-testid='yellow-button'
      id={props.id}
      className={combinedClasses}
      type={props.type}
      name={props.name}
      onClick={props.onClick}
      onKeyPress={props.onKeyPress}
      icon={props.icon}
      label={props.label}
      aria-label={props.ariaLabel}
      href={props.href}
      style={styles}
      disabled={props.disabled}
      tabIndex={props.tabIndex}
      alt={props.alt}
    />
  );
};

YellowButton.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  align: PropTypes.string,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
};

YellowButton.defaultTypes = {
  className: "",
  align: "left",
  disabled: false,
  type: "button",
};

export default YellowButton;
