import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import "./style.scss";
import "../Button/style.scss";
import { Button } from "..";

const ClearButton = (props) => {
  let combinedClasses = "";
  if (props.hoverEffect && props.noPadding) {
    combinedClasses = `btn btn-clear hover-effect no-padding ${props.className}`;
  } else if (props.noPadding) {
    combinedClasses = `btn btn-clear no-padding ${props.className}`;
  } else if (props.hoverEffect) {
    combinedClasses = `btn btn-clear hover-effect ${props.className}`;
  } else {
    combinedClasses = `btn btn-clear ${props.className}`;
  }

  const styles = {
    float: props.align,
    color: props.textColor,
  };

  const handleDownloadClick = () => {
    ReactGA.event({
      category: props.tracker.category,
      action: props.tracker.action,
      label: props.tracker.label,
    });
  };

  return (
    <Button
      id={props.id}
      aria-expanded={props.expanded}
      className={combinedClasses}
      type={props.type}
      name={props.name}
      tabIndex={props.tabIndex}
      onClick={props.tracker ? handleDownloadClick : props.onClick}
      onKeyPress={props.onKeyPress}
      style={styles}
      icon={props.icon}
      label={props.label}
      href={props.href}
      disabled={props.disabled}
      newTab={props.newTab}
      tooltipId={props.tooltipId}
      ariaLabel={props.ariaLabel || `${props.label} Button`}
    />
  );
};

ClearButton.propTypes = {
  id: PropTypes.string,
  tooltipId: PropTypes.string,
  ariaLabel: PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  align: PropTypes.string,
  hoverEffect: PropTypes.bool, // if true, button will transition to grey on hover
  noPadding: PropTypes.bool, // if true, button will have no padding
  disabled: PropTypes.bool,
  newTab: PropTypes.bool,
  expanded: PropTypes.bool,
  tracker: PropTypes.object,
};

ClearButton.defaultTypes = {
  id: "",
  className: "",
  label: "",
  align: "left",
  hoverEffect: false,
  noPadding: false,
  textColor: "#000000",
};

export default ClearButton;
