import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ErrorIcon } from "../../img/icons/alert/ErrorIcon.svg";
import { ReactComponent as ExclamationIcon } from "../../img/icons/alert/ExclamationIcon.svg";
import { ReactComponent as InformationIcon } from "../../img/icons/alert/InformationIcon.svg";
import { ReactComponent as SuccessIcon } from "../../img/icons/alert/SuccessIcon.svg";
import { ReactComponent as CloseIcon } from "../../img/icons/CloseIcon.svg";
import { ALERT_TYPES } from "../types";
import "./alert.scss";

const Alert = (props) => {
  const { alertType, message, onClose } = props;

  if (!alertType || !message) {
    return null;
  }

  let containerClass;
  let iconClass;
  let Icon;
  switch (alertType) {
    case ALERT_TYPES.ERROR:
      containerClass = "alert-error";
      iconClass = "alert-error-icon";
      Icon = ErrorIcon;
      break;
    case ALERT_TYPES.WARNING:
      containerClass = "alert-warning";
      iconClass = "alert-warning-icon";
      Icon = ExclamationIcon;
      break;
    case ALERT_TYPES.SUCCESS:
      containerClass = "alert-success";
      iconClass = "alert-success-icon";
      Icon = SuccessIcon;
      break;
    case ALERT_TYPES.INFO:
    default:
      containerClass = "alert-info";
      iconClass = "alert-info-icon";
      Icon = InformationIcon;
      break;
  }
  return (
    <div
      data-testid='alert-container'
      className={`alert-container ${containerClass}`}
    >
      <span className={`alert-icon ${iconClass}`}>
        <Icon height='30px' width='30px' />
      </span>
      <div className='alert-text'>{message}</div>
      {onClose && (
        <button type='button' className='alert-close-btn' onClick={onClose}>
          <CloseIcon title='Close button' width='12px' height='12px' />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  alertType: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // function to close the alert dialog, if no onClose function is given then the alert dialog will not have a close option
  onClose: PropTypes.func,
};

export default Alert;
