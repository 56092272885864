import React from "react";
import PropTypes from "prop-types";
import { BANNER_TYPES, bannerContents } from "./components/bannerConfig";

/**
 * HeaderBanner - A flexible alert banner component
 * @param {Object} props - Component props
 * @param {string} props.bannerType - Banner type from BANNER_TYPES
 * @param {Object} props.style - Additional inline styles (optional)
 * @param {string} props.className - Additional CSS classes (optional)
 * @returns {React.ReactElement|null}
 */
function HeaderBanner({
  bannerType = BANNER_TYPES.NONE,
  style = {},
  className = "",
}) {
  // If banner type is NONE or not defined in the config, don't render anything
  if (bannerType === BANNER_TYPES.NONE || !bannerContents[bannerType]) {
    return null;
  }

  // Get the banner configuration
  const bannerConfig = bannerContents[bannerType];

  const alertClass = `alert alert-${bannerConfig.type} mb-0 ${className}`;
  const defaultStyle = { padding: "1rem 3rem 0 4rem" };
  const combinedStyle = { ...defaultStyle, ...style };

  return (
    <div className={alertClass} style={combinedStyle}>
      <span style={{ fontSize: "1.125rem" }} className='mr-2'>
        <i className={`bi bi-${bannerConfig.icon} mr-1`}></i>
        {bannerConfig.title}
      </span>
      {bannerConfig.content}
    </div>
  );
}

HeaderBanner.propTypes = {
  bannerType: PropTypes.oneOf(Object.values(BANNER_TYPES)),
  style: PropTypes.object,
  className: PropTypes.string,
};

export default HeaderBanner;
