import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  DatePicker,
  InputBox,
  SearchSelect,
  Textarea,
} from "../../../../common/inputs";
import { ClearButton } from "../../../../common/buttons";
import { ReactComponent as TrashIcon } from "../../../../img/icons/TrashIcon.svg";

// only allow user to enter one character for middle initial
export const parseMiddleInitial = (value) => {
  if (value.match(/^[a-zA-Z]{0,1}$/)) {
    return value;
  }
  return undefined;
};

// renders the input fields (first name, last name, etc) for one researcher
const SignificantFindingsForm = (props) => {
  const { member, index, fields, type, label, minDate, maxDate } = props;

  const [selectedDate, setSelectedDate] = useState(maxDate);

  const renderRemoveBtn = () => {
    if (fields.length > 1) {
      return (
        <ClearButton
          type='button'
          onClick={() => fields.remove(index)}
          onKeyPress={() => fields.remove(index)}
          label={`Remove ${label}`}
          align='right'
          icon={<TrashIcon />}
        />
      );
    }
    return null;
  };

  let sectionHeader;
  if (type === "significantfinding") {
    sectionHeader = "SIGNIFICANT FINDINGS";
  } else if (type === "otheroutcomes") {
    sectionHeader = "OTHER OUTCOMES";
  } else {
    sectionHeader = `${_.toUpper(type)}s`;
  }

  return (
    <div>
      <div className='row'>
        <h2 className='form-heading-1'>
          {" "}
          {sectionHeader} OUTCOME INFORMATION {index + 1}
        </h2>
        <div className='col align-self-end'>{renderRemoveBtn()}</div>
      </div>
      <hr />
      <div className='row'>
        <div className='col'>
          <Field
            component={InputBox}
            id='finding-title'
            name={`${member}.title`}
            label='Finding Title'
            placeholder='Please enter the title of the finding (300 characters including spaces)'
            required
          />
        </div>
      </div>
      <div className='form-group'>
        <div className='row'>
          <div className='col'>
            <Field
              id='finding-date'
              name={`${member}.date`}
              label='Finding Date (if date is unknown please select the 1st of the month)'
              ariaLabel='Pease enter the finding date in the format mm/dd/yyyy. If date is unknown please select the 1st of the month'
              form='annualReport'
              required
              // below props are used by ReactDatePicker
              selected={selectedDate}
              maxDate={maxDate}
              minDate={minDate}
              component={DatePicker}
              placeholderText='Select the finding date'
              dateFormat='MMMM d, yyyy'
              onChange={(date) => setSelectedDate(date)}
              className='form-control'
              popperClassName='mt_-20'
              errorPopperClassName='mt-25'
              showYearDropdown
              showMonthDropdown
              dropdownMode='select'
            />
          </div>
          <div className='col'>
            <Field
              component={Textarea}
              id='finding-description'
              name={`${member}.description`}
              label='Description'
              placeholder='Please enter finding description (700 characters including spaces)'
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SignificantFindingsForm.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default SignificantFindingsForm;
