import { get } from "lodash";
import {
  ADMIN_SET_FORM_SUPPORT,
  ADMIN_SET_INITIAL_DATA,
  ADMIN_SET_ITEMS,
  ADMIN_RESET_STATE,
  OUTCOME_TYPES,
} from "../../common/types";
import { parseUploadPackageData } from "../request/components/forms/uploadPackage/UploadPackageValidation";
import { downloadFile } from "../../common/utils";

export const baseUrl = (pageType) => {
  switch (pageType) {
    case "request":
    case "outcome":
      return "/workbench-api/admin";
    case "user":
    case "institution":
    case "division":
      return "/login/admin";
    case "feedback":
      return "/feedback";
    default:
      return null;
  }
};

const getInitialUrl = (base, pageType, currPage, status, requestType) => {
  let url;
  switch (pageType) {
    case "request":
      if (status === "Annual Report Approved") {
        url = `/request/approvedAnnualReport?page=${currPage}&size=20&status=status=${status}&requestType=${requestType}`;
      } else {
        url = `/request?page=${currPage}&size=20&status=${status}&requestType=${requestType}`;
      }
      break;
    case "outcome":
      url = `/outcome-dashboard?type=${status}&page=${currPage}&size=10`;
      break;
    case "user":
      url = `/users?page=${currPage}&size=10&status=${status}`;
      break;
    case "institution":
    case "division":
      url = `/${pageType}s?page=${currPage}&size=10&status=${status}`;
      break;
    case "feedback":
      url = `/feedbacks?page=${currPage}&size=10`;
      break;
    default:
      url = null;
  }
  return `${base}${url}`;
};

const embeddedType = (pageType) => {
  switch (pageType) {
    case "request":
    case "institution":
    case "division":
    case "feedback":
      return `${pageType}s`;
    case "user":
      return "archiveUsers";
    case "outcome":
      return "approvedRequestOutcomes";
    default:
      return null;
  }
};

const filterCategoriesMap = {
  user: ["emailAddress", "institutionName", "firstName", "lastName"],
  institution: ["name"],
  request: [
    "studyName",
    "studyAbbreviation",
    "id",
    "requestor",
    "principalInvestigator",
    "requestingInstitution",
  ],
  feedback: ["emailAddress", "firstName", "lastName", "comments"],
  outcome: {
    [OUTCOME_TYPES.PUBLICATION]: [
      "title",
      "studyName",
      "studyAbbreviation",
      "requestId",
      "description",
      "source",
      "journal",
      "authors",
      "publicationUrl",
    ],
    [OUTCOME_TYPES.PRESENTATION]: [
      "title",
      "studyName",
      "studyAbbreviation",
      "requestId",
      "description",
      "source",
      "presentedTo",
    ],
    [OUTCOME_TYPES.FINDING]: [
      "title",
      "studyName",
      "studyAbbreviation",
      "requestId",
      "description",
      "source",
    ],
    [OUTCOME_TYPES.OTHER]: [
      "title",
      "studyName",
      "studyAbbreviation",
      "requestId",
      "description",
      "source",
    ],
  },
};

export const getInitialData = (statuses) => {
  const pagination = {};
  statuses.forEach((status) => {
    pagination[status] = {
      page: {
        number: 0, // current page
        size: 10, // maximum number of items per page
        totalElements: 0,
        totalPages: 0,
      },
      items: null,
    };
  });
  return pagination;
};

export const getDivisionFormSupport = (formSupportType, institutionId) => {
  let url = `/login/user/attributes/${formSupportType}`;
  if (formSupportType === "divisions") {
    url = `${url}/${institutionId}`;
  }
  return async (dispatch) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        dispatch({
          type: ADMIN_SET_FORM_SUPPORT,
          payload: { pageType: "user", formType: "profile", data },
        });
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getFormSupport = (pageType, formType) => {
  const url =
    pageType !== "outcome"
      ? `${baseUrl(pageType)}/${pageType}/${formType}`
      : `${baseUrl(pageType)}/outcome-dashboard/${formType}`;
  const init = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };

  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      if (response.ok) {
        const data = await response.json();
        if (formType === "statuses" && pageType === "request") data.push("All"); // add all
        dispatch({
          type: ADMIN_SET_FORM_SUPPORT,
          payload: { pageType, formType, data },
        });
        if (formType === "statuses") {
          dispatch({
            type: ADMIN_SET_INITIAL_DATA,
            payload: { items: getInitialData(data), pageType },
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
};

export const getInstitutionTypesFormSupport = () => {
  const url = "/login/user/attributes/institutionTypes";
  return async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

const setPaginationData = (status, page, items, pageType) => ({
  type: ADMIN_SET_ITEMS,
  payload: {
    status,
    page,
    items,
    pageType,
  },
});

export const getUrlWithSortAndFilter = (url, sort, filterQuery, filterPath) => {
  if (sort && sort.direction !== "none" && sort.column !== "none") {
    url = `${url}&sort=${sort.column},${sort.direction}`;
  }
  if (filterQuery && filterQuery !== "") {
    url = `${url}&filterEqOr=${get(filterCategoriesMap, filterPath).join(
      ",",
    )}::${filterQuery}`;
  }
  return url;
};

export const getItems = (
  status,
  currPage,
  sort,
  pageType,
  filterQuery,
  requestType,
) => {
  const initialUrl = getInitialUrl(
    baseUrl(pageType),
    pageType,
    currPage,
    status,
    requestType,
  );
  const filterPath =
    pageType !== "outcome" ? pageType : `${pageType}.${status}`;
  const url = getUrlWithSortAndFilter(
    initialUrl,
    sort,
    filterQuery,
    filterPath,
  );
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async (dispatch) => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();
      if (response.ok) {
        dispatch(
          setPaginationData(
            status,
            data.page,
            data._embedded[embeddedType(pageType)],
            pageType,
          ),
        );
      } else {
        throw data;
      }
    } catch (error) {
      throw error;
    }
  };
};

export const viewItem = (id, pageType) => {
  const url = `${baseUrl(pageType)}/${pageType}/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const viewRequestRenewal = (id) => {
  const url = `/workbench-api/admin/request/renewal/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const viewAORModification = (id) => {
  const url = `/workbench-api/admin/request/aorModification/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const viewAnnualReport = (id) => {
  const url = `/workbench-api/admin/request/annualReport/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const getProfileFormSupport = (formSupportType, institutionId) => {
  let url = `/login/user/attributes/${formSupportType}`;
  if (formSupportType === "divisions") {
    url = `${url}/${institutionId}`;
  }
  return async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };
};

export const getUsersApprovedItems = (type, id) => {
  const url =
    type === "submission"
      ? `/submission-api/admin/submission/user/${id}`
      : `/workbench-api/admin/dua/user/${id}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();
      if (response.ok) {
        return data;
      }
      return null;
    } catch (error) {
      throw error;
    }
  };
};

export const updateStatus = (status, id, pageType) => {
  const url = `${baseUrl(
    pageType,
  )}/${pageType}/${id}/updateStatus?status=${status}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
    method: "POST",
  };
  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const updateRoles = (roles, id) => {
  const url = `${baseUrl("user")}/user/${id}/updateRoles`;
  const init = {
    body: JSON.stringify(roles),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const updateUser = (values, id) => {
  const url = `${baseUrl("user")}/user/${id}/updateUser`;
  const division = values.division.id
    ? {
        id: values.division.id,
        institution: { id: values.division.institution.id },
      }
    : {
        ...values.division,
        institution: { id: values.division.institution.id },
      };

  values = { ...values, emailConfirm: values.emailAddress, division };

  const init = {
    body: JSON.stringify(values),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const toggleUserProperty = (value, id, type) => {
  const param = type === "quarterly-email" ? "optOut" : "isInternal";
  const url = `${baseUrl("user")}/user/${id}/${type}?${param}=${value}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
    method: "POST",
  };
  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};


export const createLocation = (values, locationType) => {
  const url = `${baseUrl("institution")}/${locationType.toLowerCase()}`;
  const init = {
    body: JSON.stringify(values),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };
  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const updateLocation = (values, id, locationType) => {
  const url = `${baseUrl("institution")}/${locationType}/${id}/update`;
  const init = {
    body: JSON.stringify(values),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    method: "POST",
  };

  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

const removeBiospecimenItemsFromSearch = async (ids) => {
  const searchURL = `/search/admin/removeBiospecimen?ids=${ids.toString()}`;
  const init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
  };

  const searchResponse = await fetch(searchURL, init);

  if (!searchResponse.ok) {
    const data = await searchResponse.json();
    throw data;
  }
};

const updateApprovedBiospecimen = async (requestId) => {
  const workspaceURL = `/workbench-api/admin/request/${requestId}/approvedBiospecimenItems`;
  const init = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  const getBiospecimenIdsResponse = await fetch(workspaceURL, init);

  if (getBiospecimenIdsResponse.ok) {
    const ids = await getBiospecimenIdsResponse.json();
    await removeBiospecimenItemsFromSearch(ids);
  } else {
    const data = await getBiospecimenIdsResponse.json();
    throw data;
  }
};

const formatTerminationValues = (values) => {
  const { recipients } = values;
  values.recipientIds = Object.keys(recipients).filter(
    (recipient) => recipients[recipient],
  );
  return values;
};

export const approveAnnualReport = (id) => {
  const values = { approvalDate: new Date() };
  const url = `${baseUrl("request")}/request/${id}/annualReportApprove`;
  const init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(values),
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const changeRequest = (requestId, values, changeType, requestType) => {
  const url = `${baseUrl("request")}/request/${requestId}/${changeType}`;
  if (changeType === "terminate") {
    values = formatTerminationValues(values);
  }
  let init;
  if (
    changeType === "upload-executed-use-agreement" ||
    changeType === "upload-shipment-fulfillment-form" ||
    changeType === "upload-executed-renewal-form" ||
    changeType === "upload-executed-aor-modification-form"
  ) {
    init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      },
      body: parseUploadPackageData(values),
    };
  } else {
    init = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };
  }
  return async () => {
    const response = await fetch(url, init);

    if (response.ok) {
      if (changeType === "approve" && requestType === "Biospecimen") {
        await updateApprovedBiospecimen(requestId);
      }

      if (changeType === "upload-shipment-fulfillment-form") {
        const ids = await response.json();
        await removeBiospecimenItemsFromSearch(ids);
      }
    } else {
      const data = await response.json();
      throw data;
    }
  };
};

export const downloadDocuments = (isDua, id) => {
  let url;
  if (isDua) {
    url = `/workbench-api/dua/${id}/download-documents`;
  } else {
    url = `/workbench-api/admin/request/${id}/download-documents`;
  }
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
  };
  return downloadFile(url, init);
};

export const getTerminationReasons = () => {
  const url = `${baseUrl("request")}/request/terminationReasons`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const downloadQuarterlyEmailRecipientsList = () => {
  const url = "/reports/admin/user/quarterly-email-recipients";
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const getKeepInstitutions = () => {
  const url = "/login/admin/institutionList";
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return data;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const getKeepDivisions = (divisionId, institutionId) => {
  const url = `/login/admin/institution/${institutionId}`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        if (data && data.divisions?.length > 0) {
          return {
            discardDivision: data.divisions.find((d) => d.id === divisionId),
            keepDivisions: data.divisions.filter((d) => d.id !== divisionId),
          };
        }
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const canMergeInstitution = (id) => {
  const url = `/login/admin/institution/${id}/canMerge`;
  const init = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
    },
  };
  return async () => {
    try {
      const response = await fetch(url, init);
      const data = await response.json();

      if (response.ok) {
        return !data.hasApprovedRequests;
      }
      throw data;
    } catch (error) {
      throw error;
    }
  };
};

export const mergeInstitutions = (keepInstitutionId, discardInstitutionId) => {
  const url = `/login/admin/institution/merge?keepInstitutionId=${keepInstitutionId}&discardInstitutionId=${discardInstitutionId}`;
  const init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
  };

  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const mergeDivisions = (keepDivisionId, discardDivisionId) => {
  const url = `/login/admin/division/merge?keepDivisionId=${keepDivisionId}&discardDivisionId=${discardDivisionId}`;
  const init = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      "Content-Type": "application/json",
    },
  };

  return async () => {
    const response = await fetch(url, init);

    if (!response.ok) {
      const data = await response.json();
      throw data;
    }
  };
};

export const resetState = (pageType) => ({
  type: ADMIN_RESET_STATE,
  payload: { pageType },
});
