import React, { useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  DatePicker,
  InputBox,
  SearchSelect,
  Textarea,
} from "../../../../common/inputs";
import { ClearButton } from "../../../../common/buttons";
import { ReactComponent as TrashIcon } from "../../../../img/icons/TrashIcon.svg";

// renders the input fields (first name, last name, etc) for one researcher
const PatentForm = (props) => {
  const { member, index, fields, type, label, minDate, maxDate } = props;

  const [selectedDate, setSelectedDate] = useState(maxDate);

  const renderRemoveBtn = () => {
    if (fields && fields.length > 1) {
      return (
        <ClearButton
          type='button'
          onClick={() => fields.remove(index)}
          onKeyPress={() => fields.remove(index)}
          label={`Remove ${type}`}
          align='right'
          icon={<TrashIcon />}
        />
      );
    }
    return null;
  };

  let sectionHeader;
  if (type === "significantfinding") {
    sectionHeader = "SIGNIFICANT FINDINGS";
  } else if (type === "otheroutcomes") {
    sectionHeader = "OTHER OUTCOMES";
  } else {
    sectionHeader = `${_.toUpper(type)}s`;
  }

  return (
    <div>
      <div className='row'>
        <h2 className='form-heading-1'>
          {" "}
          {sectionHeader} OUTCOME INFORMATION {index + 1}
        </h2>
        <div className='col align-self-end'>{renderRemoveBtn()}</div>
      </div>
      <hr />
      <div className='row'>
        <div className='col'>
          <Field
            component={InputBox}
            id='patent-title'
            name={`${member}.title`}
            label='Patent Title'
            placeholder='Please enter the title of the patent (300 characters including spaces)'
            required
          />
        </div>
        <div className='col'>
          <Field
            id='patent-date'
            label='Patent Submission Date (if date is unknown please select the 1st of the month)'
            name={`${member}.date`}
            ariaLabel='Please enter the patent submission date in the format mm/dd/yyyy. If date is unknown please select the 1st of the month'
            form='annualReport'
            // touchFormField={props.touchFormField} // NOT SURE IF WE NEED THIS!
            required
            // below props are used by ReactDatePicker
            selected={selectedDate}
            maxDate={maxDate}
            minDate={minDate}
            component={DatePicker}
            placeholderText='Select the submission date'
            dateFormat='MMMM d, yyyy'
            onChange={(date) => setSelectedDate(date)}
            className='form-control'
            popperClassName='mt_-20'
            errorPopperClassName='mt-25'
            showYearDropdown
            showMonthDropdown
            dropdownMode='select'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Field
            component={InputBox}
            id='patent'
            name={`${member}.patentNumber`}
            label='Patent Number'
            placeholder='Please enter the Patent Number'
            required
          />
        </div>
        <div className='col'>
          <Field
            component={InputBox}
            id='patent-application'
            name={`${member}.patentApplicationNumber`}
            label='Provisional/Actual Patent Application Number'
            placeholder='Please enter the Provisional/Actual Patent Application Number'
            required
          />
        </div>
      </div>
      <div className='form-group'>
        <div className='row'>
          <div className='col'>
            <Field
              component={Textarea}
              id='patent-description'
              name={`${member}.description`}
              label='Description of newly discovered/developed tool or technology'
              placeholder='Please enter patent description (700 characters including spaces)'
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PatentForm.propTypes = {
  member: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date).isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
  label: PropTypes.string.isRequired,
};

export default PatentForm;
